import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderPage } from "../components/structure"
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HeaderPage
      // color={props => props.theme.procolors.digitalDark}
      bgColor={props => props.theme.procolors.bgFirst}
      title="Oops something went wrong"
      catchy="Try again or contact our team"
    />
  </Layout>
)

export default NotFoundPage
